<template>
  <div class="container mt-5">
    <div class="row d-flex justify-content-center">
      <div class="col main-content__container fit-content">
        <div class="card fit-content">
          <div class="card-header d-flex justify-content-between">
            <h5 class=" mt-1 mb-0">
              User management
            </h5>
            <button
              v-if="isAddNewBtnVisible"
              class="btn btn-sm btn-primary"
              @click="addNewClickHandler"
            >
              Add new
            </button>
          </div>
          <div class="card-body p-0 ag-grid-container grid-container">
            <CustomerPortalUsersManagementTable
              @rowClick="rowClickHandler"
            />
          </div>
        </div>
      </div>
      <CustomerPortalUserEditModal
        ref="customer-portal-user-edit-modal"
        :viewType="modalViewType"
      />
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import CustomerPortalUserEditModal from './CustomerPortalUserEditModal';
import CustomerPortalUsersManagementTable from './CustomerPortalUsersManagementTable';
import { modalViewTypes, userPrivileges } from '@/components/constants';
import { hasPrivilege } from '@/service/userAccess';

export default {
  components: {
    CustomerPortalUsersManagementTable,
    CustomerPortalUserEditModal,
  },
  data() {
    return {
      modalViewType: modalViewTypes.edit
    };
  },
  computed: {
    isAddNewBtnVisible(){
      return hasPrivilege(userPrivileges.createCustomerPortalUser);
    }
  },
  async mounted() {
    await Promise.all([
      this.fetchCustomerPortalUsers(),
      this.fetchBrandNames()
    ]);
  },
  methods: {
    ...mapActions({
      fetchCustomerPortalUsers: 'customerPortalUsersManagement/fetchUsers',
      fetchBrandNames: 'brandManagement/fetchBrandNames'
    }),
    rowClickHandler(rowId) {
      if (!hasPrivilege(userPrivileges.updateCustomerPortalUser)) {
        return;
      }
      this.modalViewType = modalViewTypes.edit;
      this.$refs['customer-portal-user-edit-modal'].initModal(rowId);
    },
    addNewClickHandler() {
      this.modalViewType = modalViewTypes.add;
      this.$refs['customer-portal-user-edit-modal'].initModal();
    }
  }
};
</script>
<style scoped>
.main-content__container {
  width: 100%;
}

.fit-content {
  width: fit-content;
}

.grid-container {
  height: 600px;
  width: 1130px;
}

</style>
